/* Theme Variables */
:root {
  --background-color: #1b1f34;
  --text-color: #f9f9f9;
  --navbar-background: #282a36;
  --navbar-text-color: #f9f9f9;
  --navbar-hover-background: #333;
  --app-container-background: #23263b;
  --button-background: #4caf50;
  --button-text-color: white;
  --button-hover-background: #45a049;
  --input-background: #2c2c2c;
  --input-border-color: #555;
  --status-message-color: #aaa;
  --default-image-color: #888;
}

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1, h3 {
  text-align: center;
  color: #fff;
}

.app {
  min-height: 100vh;
}

/* Navbar Styles */
.navbar {
  background-color: var(--navbar-background);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-menu ul {
  display: flex;
  gap: 1.5rem;
  list-style: none;
}

.nav-link {
  color: var(--navbar-text-color);
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover,
.nav-link.active {
  color: #fff;
}

.wallet-connection {
  color: var(--navbar-text-color);
  font-size: 0.9rem;
}

.wallet-connection span {
  font-weight: bold;
  color: var(--text-color);
}

.wallet-connection small {
  font-size: 0.8em;
  color: var(--default-image-color);
  word-break: break-all;
}

/* Content Area */
.content {
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
}

/* Card Grid for NFTs */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.card {
  background-color: var(--app-container-background);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-title {
  color: #ff8c00;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.card-info {
  color: var(--default-image-color);
  font-size: 0.9rem;
}

/* Button Styles */
button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover:enabled {
  background-color: var(--button-hover-background);
}

button:disabled {
  background-color: #ccc;
}

/* Status Message */
.status-message {
  margin-top: 15px;
  color: var(--status-message-color);
  text-align: center;
}

/* NFT Container Styles */
.nft-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--app-container-background);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nft-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.nft-item p {
  margin-top: 0.5rem;
  color: #ff8c00;
  font-size: 1.1rem;
}

/* App Container */
.app-container {
  background-color: var(--app-container-background);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-menu ul {
    flex-direction: column;
  }

  .wallet-connection {
    align-self: flex-end;
    margin-top: 10px;
  }

  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
.inventory-container {
  padding: 2rem;
  text-align: center;
}

.nft-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
}

.nft-item {
  background-color: #23263b;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 600px; /* Limit width for larger screens */
  margin: auto;
}

.nft-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.nft-item p {
  color: #ff8c00;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

/* Media Queries for Responsive Layout */
@media (min-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
